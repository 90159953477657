import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams, NavLink } from "react-router-dom";
import Sidebar from "./Sidebar/Sidebar";
import { IoMdArrowRoundBack } from "react-icons/io";
import Image1 from "../components/assets/doctorimg.jpg";
// import Image2 from '../components/assets/birthday.jpg'
import Image2 from "../components/assets/Birthdayimg.jpg";
import Image3 from "../components/assets/thumb.png";
import Image4 from "../components/assets/teacher_thumb.png";
import Image5 from "../components/assets/typhoid thumbnail.png";
import Image6 from "../components/assets/Techer_Thumbnails.png";
import Image7 from "../components/assets/Grow.jpg"



const HomePage = () => {
  const navigate = useNavigate();
  const { MRID } = useParams();

  const gototemp = (videoname, name) => {
    if (name === "Doctor's Day") {
      navigate(`/temp/${videoname}/${MRID}/${name}`);
    } else if (name === "Doctor's Day2") {
      navigate(`/temp/${videoname}/${MRID}/${name}`);
    } else if (name === "BirthDayTest") {
      navigate(`/temp2/${videoname}`);
    } else if (name === "Birthday") {
      navigate(`/temp3/${videoname}/${MRID}/${name}`);
    } else if (name === "Layout4") {
      navigate(`/temp4/${videoname}`);
    } else if (name === "Layout5") {
      navigate(`/temp5/${videoname}`);
    } else if (name === "Layout6") {
      navigate(`/temp6/${videoname}`);
    } else if (name === "Layout7") {
      navigate(`/temp7/${videoname}`); // DoctorDay_NEW
    } else if (name === "Layout8") {
      navigate(`/temp8/${videoname}`); // Grow
    }
    
  };

  const handleNavigate = () => {
    navigate(`/temp2/${MRID}`);
  };

  const BusinessUnit = localStorage.getItem("BusinessUnit");

  const handleNavigate1 = () => {
    navigate(`/temp4/${MRID}`);
  };

  const handleNavigate2 = () => {
    navigate(`/temp5/${MRID}`);
  };

  const handleNavigate3 = () => {
    navigate(`/temp6/${MRID}`);
  };

  const handleNavigate4 = () => {
    navigate(`/temp7/${MRID}`);
  };

  const handleNavigate5 = () => {
    navigate(`/temp8/${MRID}`);
  };


  const [allVideName, setAllVideoName] = useState([]);
  const [allVideoData, setAllVideoData] = useState([]);

  const videoRef = useRef(null);

  // const fetchVideoName = () => {
  //   fetch("https://dummy.digilateral.com/api/auth/all-video-name")
  //     .then((res) => res.json())
  //     .then((data) => {
  //       setAllVideoName(data);
  //     });
  // };

  // useEffect(() => {
  //   fetchVideoName();
  // }, []);

  const fetchVideoName = () => {
    fetch("https://dummy.digilateral.com/api/auth/all-video-name")
      .then((res) => res.json())
      .then((data) => {
        // Combine video data with imported poster images
        const updatedVideoData = data.map((video) => {
          let randomImage;
          switch (video.name) {
            // case "Doctor's Day":
            //   randomImage = Image1;
            //   break;
            // case "Birthday":
            //   randomImage = Image2;
            //   break;
            // Add cases for other video names as needed
            default:
              // Use a default image if no match is found
              randomImage = Image1;
              break;
          }
          return { ...video, posterImage: randomImage };
        });
        setAllVideoData(updatedVideoData);
      });
  };
  useEffect(() => {
    fetchVideoName();
  }, []);

  useEffect(() => {
    document.addEventListener("contextmenu", (event) => event.preventDefault());
    return () => {
      document.removeEventListener("contextmenu", (event) =>
        event.preventDefault()
      );
    };
  }, []);

  return (
    <>
      <div className="flex justify-center items-center overflow-x-hidden  ">
        <div className="w-[100vw]">
          <Sidebar />
        </div>

        <div className="absolute top-[34px] w-[335px]  sm:w-[750px] lg:left-[140px] sm:left-[10px] xl:left-[450px]  ">
          <div className=" w-full">
            <div className="flex flex-row justify-between items-center relative top-[-8px] ">
              <NavLink
                className="p-5 flex relative left-[-36px] mt-20 "
                to={`/welcome/${MRID}`}
              >
                {/* <div
                  style={{ backgroundColor: "#F58420", color: "white" }}
                  className="p-[12px] drop-shadow-lg    h-10 w-10   rounded-full relative  top-[-4px] left-[17px] sm:left-[20px]  "
                >
                  <IoMdArrowRoundBack />
                </div> */}
              </NavLink>
              <div>

              </div>
              <div className="text-black text-[20px] sm:flex sm:justify-center sm:items-center  font-bold relative left-[-120px] sm:left-[-240px] top-[-2px] sm:top-[30px]   ">
                <p>Video Cards</p>
              </div>  
            </div>

            {/* <div className="font-bold text-[11px] top-[-34px] relative left-[105px] sm:left-[251px] w-[250px]   sm:text-center   text-[rgba(158,156,158,1)]">
              <p>Please select your Video.</p>
            </div> */}
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2  gap-4 relative top-[-26px] sm:top-0 sm:w-auto">
            {/* {allVideoData.map((videoData) => (
              <div
                key={videoData.video}
                className="relative flex justify-center items-center  "
              >
                <div className="bg-white rounded-lg overflow-hidden shadow-lg hover:shadow-2xl transition-all duration-300 w-[335px]">
                  <div className="h-[300px] w-full overflow-hidden cursor-pointer">
                    <video
                      ref={videoRef}
                      width="100%"
                      className="rounded-md shadow-md h-full cursor-pointer"
                      onClick={() => {
                        gototemp(videoData.video, videoData.name);
                      }}
                      poster={videoData.posterImage} // Assign the imported poster image
                    >
                      <source
                        src={`https://dummy.digilateral.com/${videoData.video}`}
                        type="video/mp4"
                      />
                    </video>
                  </div>

                  <div className="h-[35px] relative top-[8px] text-center text-white bg-orange-500 font-extrabold text-xl">
                    <h5 className="text-center">{videoData.name}</h5>
                  </div>
                </div>
              </div>
            ))} */}
            {BusinessUnit === "DigiLateral" ||
            MRID === "T1234" ||
            BusinessUnit === "Respicare" || 
            BusinessUnit === "Glenmark-Respicare" ||
            BusinessUnit === "DigiLateral-Grow" ||
            BusinessUnit === "Doctor-DigiLateral"
              ? ""
              : allVideoData.slice(-1, 3).map((videoData) => (
                  <div
                    key={videoData.video}
                    className="relative flex justify-center items-center  sm:w-[61vw] w-[101]"
                  >
                    <div className="bg-white rounded-lg overflow-hidden shadow-lg hover:shadow-2xl transition-all duration-300 w-[335px]">
                      <div className="h-[300px] w-full overflow-hidden cursor-pointer">
                        <video
                          ref={videoRef}
                          width="100%"
                          className="rounded-md shadow-md h-full cursor-pointer"
                          onClick={() => {
                            gototemp(videoData.video, videoData.name);
                          }}
                          poster={videoData.posterImage} // Assign the imported poster image
                        >
                          <source
                            src={`https://dummy.digilateral.com/${videoData.video}`}
                            type="video/mp4"
                          />
                        </video>
                      </div>
                      <div className="h-[35px] relative top-[8px] text-center text-white bg-orange-500 font-extrabold text-xl">
                        <h5 className="text-center">{videoData.name}</h5>
                      </div>
                    </div>
                  </div>
                ))}

            {/* {MRID === "D1234" ? (
              <div className="relative flex justify-center items-center  sm:w-[61vw] w-[101]">
                <div className="bg-white rounded-lg overflow-hidden shadow-lg hover:shadow-2xl transition-all duration-300 w-[335px]">
                  <div className="h-[300px] flex justify-center items-center w-full overflow-hidden cursor-pointer">
                    {/* <video
                      ref={videoRef}
                      width="100%"
                      className="rounded-md shadow-md h-full cursor-pointer"
                      onClick={() => {
                        gototemp(videoData.video, videoData.name);
                      }}
                      poster={videoData.posterImage} // Assign the imported poster image
                    >
                      <source
                        src={`https://dummy.digilateral.com/${videoData.video}`}
                        type="video/mp4"
                      />
                    </video> */}
                   {/* <img
                      onClick={handleNavigate}
                      src={Image3}
                      width="100%"
                      alt="Yoga"
                    />
                  </div>
                  <div className="h-[35px] relative top-[8px] text-center text-white bg-orange-500 font-extrabold text-xl">
                    <h5 className="text-center">Yoga Video</h5>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )} */}

            {BusinessUnit === "DigiLateral" ? (
              <div className="relative flex justify-center items-center  sm:w-[61vw] w-[101]">
                <div className="bg-white rounded-lg overflow-hidden shadow-lg hover:shadow-2xl transition-all duration-300 w-[335px]">
                  <div className="h-[300px] flex justify-center items-center w-full overflow-hidden cursor-pointer">
                    {/* <video
                      ref={videoRef}
                      width="100%"
                      className="rounded-md shadow-md h-full cursor-pointer"
                      onClick={() => {
                        gototemp(videoData.video, videoData.name);
                      }}
                      poster={videoData.posterImage} // Assign the imported poster image
                    >
                      <source
                        src={`https://dummy.digilateral.com/${videoData.video}`}
                        type="video/mp4"
                      />
                    </video> */}
                    <img
                      onClick={handleNavigate}
                      src={Image3}
                      width="100%"
                      alt="Yoga"
                    />
                  </div>
                  <div className="h-[35px] relative top-[8px] text-center text-white bg-orange-500 font-extrabold text-xl">
                    <h5 className="text-center">Yoga Video</h5>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

            {MRID === "T1234" ? (
              <div className="relative flex justify-center items-center  sm:w-[61vw] w-[101]">
                <div className="bg-white rounded-lg overflow-hidden shadow-lg hover:shadow-2xl transition-all duration-300 w-[335px]">
                  <div className="h-[300px] flex justify-center items-center w-full overflow-hidden cursor-pointer">
                    {/* <video
                      ref={videoRef}
                      width="100%"
                      className="rounded-md shadow-md h-full cursor-pointer"
                      onClick={() => {
                        gototemp(videoData.video, videoData.name);
                      }}
                      poster={videoData.posterImage} // Assign the imported poster image
                    >
                      <source
                        src={`https://dummy.digilateral.com/${videoData.video}`}
                        type="video/mp4"
                      />
                    </video> */}
                    <img
                      onClick={handleNavigate1}
                      src={Image4}
                      width="100%"
                      alt="Yoga"
                    />
                  </div>
                  <div className="h-[35px] relative top-[8px] text-center text-white bg-orange-500 font-extrabold text-xl">
                    <h5 className="text-center">Teacher Day Video</h5>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

            {BusinessUnit === "Respicare" ? (
              <div className="relative flex justify-center items-center  sm:w-[61vw] w-[101]">
                <div className="bg-white rounded-lg overflow-hidden shadow-lg hover:shadow-2xl transition-all duration-300 w-[335px]">
                  <div className="h-[300px] flex justify-center items-center w-full overflow-hidden cursor-pointer">
                    <img
                      onClick={handleNavigate2}
                      src={Image5}
                      width="100%"
                      className="border-2 shadow-sm"
                      alt="Typhoid-Monsoon"
                    />
                  </div>
                  <div className="h-[35px] relative top-[8px] text-center text-white bg-orange-500 font-extrabold text-xl">
                    <h5 className="text-center">Typhoid Video</h5>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

            {BusinessUnit === "Glenmark-Respicare" ? (
              <div className="relative flex justify-center items-center  sm:w-[61vw] w-[101]">
                <div className="bg-white rounded-lg overflow-hidden shadow-lg hover:shadow-2xl transition-all duration-300 w-[335px]">
                  <div className="h-[300px] flex justify-center items-center w-full overflow-hidden cursor-pointer">
                    <img
                      onClick={handleNavigate3}
                      src={Image6}
                      width="100%"
                      className="border-2 shadow-sm"
                      alt="Teacher Day"
                    />
                  </div>
                  <div className={`"h-[35px] relative top-[8px] text-center text-white bg-[#F58420]  font-extrabold text-xl" `}>
                    <h5 className={`"text-center" ${BusinessUnit === 'Glenmark-Respicare' ? "bg-[#F58420]"   : 'bg-[#F58420]'} `}>Teacher's Day</h5>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}


            {BusinessUnit === "Doctor-DigiLateral" ? (
              <div className="relative flex justify-center items-center  sm:w-[61vw] w-[101]">
                <div className="bg-white rounded-lg overflow-hidden shadow-lg hover:shadow-2xl transition-all duration-300 w-[335px]">
                  <div className="h-[300px] flex justify-center items-center w-full overflow-hidden cursor-pointer">
                    <img
                      onClick={handleNavigate4}
                      src={Image1}
                      width="100%"
                      className="border-2 shadow-sm"
                      alt="Doctor Day"
                    />
                  </div>
                  <div className={`"h-[35px] relative top-[8px] text-center text-white bg-[#F58420]  font-extrabold text-xl" `}>
                    <h5 className={`"text-center" ${BusinessUnit === 'Doctor-DigiLateral' ? "bg-[#F58420]"   : 'bg-[#F58420]'} `}>Doctor's Day</h5>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

            {BusinessUnit === "DigiLateral-Grow" ? (
              <div className="relative flex justify-center items-center  sm:w-[61vw] w-[101]">
                <div className="bg-white rounded-lg overflow-hidden shadow-lg hover:shadow-2xl transition-all duration-300 w-[335px]">
                  <div className="h-[300px] flex justify-center items-center w-full overflow-hidden cursor-pointer">
                    <img
                      onClick={handleNavigate5}
                      src={Image7}
                      width="100%"
                      className="border-2 shadow-sm"
                      alt="Doctor Day"
                    />
                  </div>
                  <div className={`"h-[35px] relative top-[8px] text-center text-white bg-[#F58420]  font-extrabold text-xl" `}>
                    <h5 className={`"text-center" ${BusinessUnit === 'Doctor-DigiLateral' ? "bg-[#F58420]"   : 'bg-[#F58420]'} `}>Grow</h5>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

          </div>
        </div>
      </div>
    </>
  );
};

export default HomePage;


